import * as S from './Promises.styles'

export type PromisesProps = {
  isColored?: boolean
  promises: {
    promise: string
    title: string
  }[]
}

export const Promises: React.FC<PromisesProps> = ({ isColored = false, promises, ...props }) => (
  <S.Container $isColored={isColored} {...props}>
    {promises.map(({ promise, title }, index) => (
      <S.Promise key={index}>
        <S.PictoWrapper $isColored={isColored}>
          <S.Picto />
        </S.PictoWrapper>
        <div>
          <S.Title>{title}</S.Title>
          <S.Description>{promise}</S.Description>
        </div>
      </S.Promise>
    ))}
  </S.Container>
)
