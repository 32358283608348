import { ActionInputProps } from '@pretto/website/src/components/ContentPage/components/BannerCard/components/ActionInput'
import { FormatedImage } from '@pretto/website/src/components/FormatedImage/FormatedImage'
import type { PromiseFormated } from '@pretto/website/src/templates/blocs/types/promisies.type'

import * as S from './HeroInput.styles'

export type HeroInputProps = {
  arePromisesDisplayed?: boolean
  description: string
  image: string
  inputProps: ActionInputProps
  isHero: boolean
  isHeroWithPromises: boolean
  promises: PromiseFormated[]
  title: string
}

export const HeroInput: React.FC<HeroInputProps> = ({
  arePromisesDisplayed,
  description,
  image,
  inputProps,
  isHero,
  isHeroWithPromises,
  promises,
  title,
  ...props
}) => (
  <S.NewBlocRow isHero={isHero} isHeroWithPromises={isHeroWithPromises} {...props}>
    <S.ContentWrapper>
      <S.Title $isHero={isHero}>{title}</S.Title>
      <S.Description allowedTypes={['strong']}>{description}</S.Description>
      <S.MobileImageWrapper>
        <FormatedImage src={image} format="rectangle" isRounded />
      </S.MobileImageWrapper>
      <S.Input {...inputProps} />
    </S.ContentWrapper>

    <S.ImageWrapper>
      <FormatedImage src={image} format="rectangle" isRounded />
    </S.ImageWrapper>

    {arePromisesDisplayed && promises.length > 1 && <S.Promises promises={promises} />}
  </S.NewBlocRow>
)
