import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import RichContent from '@pretto/bricks/website/about/components/RichContent'

import { ActionInput } from '@pretto/website/src/components/ContentPage/components/BannerCard/components/ActionInput'
import { Promises as PromisesComponent } from '@pretto/website/src/components/Promises/Promises'
import { NewBlocRow as NewBlocRowComponent } from '@pretto/website/src/templates/blocs/components/NewBlocRow'

import styled from 'styled-components'

export const NewBlocRow = styled(NewBlocRowComponent)`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.neutral4};
`

export const ContentWrapper = styled.div`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 5])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 6])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 5])};
  }
`

type TitleProps = {
  $isHero: boolean
}

export const Title = styled.div.attrs<TitleProps>(({ $isHero }) => ({ as: $isHero ? 'h1' : 'div' }))<TitleProps>`
  ${({ theme }) => theme.typos.heading3};
  margin-bottom: ${g(3)};
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    hyphens: unset;
  }
`

export const Description = styled(RichContent)`
  ${({ theme }) => theme.typos.body4};
  margin-bottom: ${g(3)};
`

export const MobileImageWrapper = styled.div`
  width: 100%;
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`

export const Input = styled(ActionInput)`
  @media screen and (min-width: ${breakpoints.tablet}) {
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
  }
`

export const ImageWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([7, 3])};
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: flex-start;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([9, 5])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([9, 4])};
  }
`

export const Promises = styled(PromisesComponent)`
  ${column([2, 4])};
  z-index: 2;
  margin-top: ${g(5)};
  margin-bottom: ${g(-4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    margin-top: ${g(6)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    margin-top: ${g(8)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 10])};
  }
`
