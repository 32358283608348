import { CheckBold } from '@pretto/picto'
import { HeroInput } from '@pretto/website/src/templates/blocs/rows/heroInput/components/HeroInput'
import type { Promise } from '@pretto/website/src/templates/blocs/types/promisies.type'

type HeroInputContainerProps = {
  arePromisesDisplayed: boolean
  bannerId?: string
  checkMessage: string
  image: string
  inputPlaceholder: string
  isCheckMandatory: boolean
  promises?: Promise[]
  successMessage: string
  text: string
  title: string
  zap: string
}

export const HeroInputContainer: React.FC<HeroInputContainerProps> = ({
  arePromisesDisplayed,
  bannerId,
  checkMessage,
  image,
  inputPlaceholder,
  isCheckMandatory,
  promises,
  successMessage,
  text,
  title,
  zap,
}) => {
  const formatPromises =
    arePromisesDisplayed && promises && promises.length > 0
      ? promises.map(({ promise, title }) => ({ picto: CheckBold, promise, title }))
      : []

  const props = {
    arePromisesDisplayed: true,
    description: text,
    image,
    inputProps: {
      bannerId: `heroInput-${bannerId}`,
      checkMessage,
      inputPlaceholder,
      isCheckMandatory,
      successMessage,
      zap,
    },
    isHero: true,
    isHeroWithPromises: arePromisesDisplayed && formatPromises.length > 1,
    promises: formatPromises,
    title,
  }

  return <HeroInput {...props} />
}
