import { breakpoints, g } from '@pretto/bricks/components/layout'

import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'

import { CheckBoldMini } from '@pretto/picto'

import styled, { css } from 'styled-components'

type isColoredProps = {
  $isColored: boolean
}

export const Container = styled.div<isColoredProps>`
  background-color: ${({ $isColored, theme }) => ($isColored ? theme.colors.neutral5 : theme.colors.white)};
  border-radius: ${g(1)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${g(2)};
  padding: ${g(3)} ${g(2)};

  ${({ $isColored, theme }) =>
    !$isColored &&
    css`
      box-shadow: 0px ${g(1)} ${g(2)} 0px ${addAlpha(theme.colors.neutral1, 0.04)};
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    padding: ${g(3)} ${g(4)};
    gap: ${g(3)};
  }
`

export const Promise = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: ${g(2)};
  flex: 1;

  @media screen and (min-width: ${breakpoints.tablet}) {
    gap: ${g(3)};
    text-align: unset;
  }
`

export const PictoWrapper = styled.div<isColoredProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${g(1)};
  background-color: ${({ $isColored, theme }) => ($isColored ? theme.colors.neutral4 : theme.colors.neutral5)};
  border-radius: 100%;
  width: ${g(4)};
  height: ${g(4)};
  flex-shrink: 0;
`

export const Picto = styled(CheckBoldMini)`
  display: block;
`

export const Title = styled.div`
  ${({ theme }) => theme.typos.heading5};
  margin-bottom: ${g(1)};
`

export const Description = styled.div`
  ${({ theme }) => theme.typos.caption};
`
